import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { CustomModal, SearchDiv } from "./inventoryStyle";
import { Button, Col, Form, Input, Row, Select, AutoComplete } from "antd";
import { useAppSelector } from "../../redux/hooks";
import { api } from "../../api";
import getSymbolFromCurrency from "currency-symbol-map";
import { DeleteOutlined } from "@ant-design/icons";
import { removeSelectedProduct } from "../../redux/actions/productActions";
import { useDispatch } from "react-redux";

const { Option } = Select;
const CustomSelect = styled(Select)`
  border-radius: 15px;
`;
interface EditSellerModaleProps {
  isModalVisible: boolean;
  order: any;
}
const EditSellerModale = ({ isModalVisible, order }: EditSellerModaleProps) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const updateOrdersInfo = async (payload: any) => {
    const { data, error } = await api.provide(
      "post",
      "/api/orders/updateOrderInfo",
      payload
    );
    if (error) throw error;
    return data;
  };

  const handleCompleteOrder = (values: any) => {
    const updatedData = {
      sellerName: values.buyerName,
      sellerEmail: values.buyerEmail,
      sellerPhone: values.phonePrefix + values.phoneNumber,
      id: order.id,
    };
    updateOrdersInfo(updatedData)
      .then(() => {
        history.push("/completeOrder");
      })
      .catch((error) => {
        console.error("Error updating order info:", error);
      });
  };

  const onFinish = (values: any) => {
    handleCompleteOrder(values);
  };
  const handleSkip = () => {
    history.push("/completeOrder");
  };
  return (
    <CustomModal
      title={"Seller Info"}
      width={425}
      visible={isModalVisible}
      closeIcon={"Skip"}
      onCancel={handleSkip} // Submit the form on cancel
      footer={[
        <div
          style={{ display: "flex", justifyContent: "flex-end" }}
          key="footer"
        >
          <Button
            key="submit"
            type="primary"
            style={{
              padding: "8px 40px",
              height: 52,
              borderRadius: "8px",
              background: "black",
            }}
            onClick={() => form.submit()} // Submit the form on next button click
          >
            Next
          </Button>
        </div>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="buyerName" label="Buyer Name">
          <Input
            style={{ borderRadius: "5px" }}
            placeholder="Enter buyer name"
          />
        </Form.Item>

        <Form.Item
          name="buyerEmail"
          label="Buyer Email"
          rules={[
            { required: true, message: "Please enter the buyer email" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        >
          <Input
            placeholder="Enter buyer email"
            style={{ borderRadius: "5px" }}
          />
        </Form.Item>
        <Form.Item label="Phone Number" style={{ marginBottom: 0 }}>
          <Form.Item
            name="phonePrefix"
            style={{ display: "inline-block", width: "30%" }}
          >
            <Select style={{ borderRadius: "5px" }}>
              <Option value="1">+1</Option>
              <Option value="91">+91</Option>
              <Option value="44">+44</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[
              { required: true, message: "Please enter the phone number" },
            ]}
            style={{ display: "inline-block", width: "70%" }}
          >
            <Input
              placeholder="Enter phone number"
              style={{ borderRadius: "5px" }}
            />
          </Form.Item>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

interface EditBuyerModaleProps {
  isModalVisible: boolean;
  selectedOrders: any;
  order: any;
  setIsBuyerModaleVisible: () => void;
}

const EditBuyerModale = ({
  isModalVisible,
  selectedOrders,
  order,
  setIsBuyerModaleVisible,
}: EditBuyerModaleProps) => {
  const [sellerModale, setSellerModale] = useState<boolean>(false);
  const [orderSeller, setOrderSeller] = useState<any[]>();
  const [form] = Form.useForm();

  const handleModale = () => {
    setIsBuyerModaleVisible();
  };

  const handleSubmit = (values: any) => {
    const updatedData = { ...values, id: order.id };
    openSellerModale(updatedData);
  };

  const updateOrdersInfo = async (payload: any) => {
    const { data, error } = await api.provide(
      "post",
      "/api/orders/updateOrderInfo",
      payload
    );
    if (error) throw error;
    return data;
  };
  useEffect(() => {
    setOrderSeller(order);
  }, [orderSeller]);

  const openSellerModale = async (payload: any) => {
    await updateOrdersInfo(payload);
    setSellerModale(true);
  };

  return (
    <>
      {sellerModale && (
        <EditSellerModale order={orderSeller} isModalVisible={sellerModale} />
      )}
      {isModalVisible && (
        <CustomModal
          title={`Order #${"3434"}`}
          width={425}
          visible={isModalVisible}
          onCancel={handleModale}
          footer={null} // Make sure footer is set to null to include buttons within the form
        >
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item name="buyerName" label="Buyer Name">
              <AutoComplete
                className="custom-auto-complete"
                options={[{ value: "Buyer 1" }, { value: "Buyer 2" }]}
                placeholder="Select or enter buyer"
                filterOption={(inputValue, option) =>
                  option!.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="shippingMethod" label="Shipping Method">
                  <Select placeholder="Select shipping method">
                    <Select.Option value="standard">Standard</Select.Option>
                    <Select.Option value="express">Express</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="shippingCost" label="Shipping Cost">
                  <Input type="number" placeholder="Enter shipping cost" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="paymentDetail" label="Payment Details">
              <Select placeholder="Select payment method">
                <Select.Option value="creditCard">Credit Card</Select.Option>
                <Select.Option value="paypal">PayPal</Select.Option>
                <Select.Option value="bankTransfer">
                  Bank Transfer
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="primary"
                  style={{
                    padding: "8px 40px",
                    height: 52,
                    borderRadius: "8px",
                    border: "1px solid #DDDDDD",
                    color: "black",
                    background: "#F4F4F4",
                  }}
                  onClick={handleModale}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  style={{
                    padding: "8px 40px",
                    height: 52,
                    borderRadius: "8px",
                    background: "black",
                  }}
                  onClick={() => form.submit()} // Trigger form submission
                >
                  Next
                </Button>
              </div>
            </Form.Item>
          </Form>
        </CustomModal>
      )}
    </>
  );
};

export const OrderForm = (props: any) => {
  const { selectedProducts } = props;
  const [form] = Form.useForm();

  const handleSubmit = (values: any) => {
    const updatedData = { ...values };
    openSellerModale(updatedData);
  };

  const updateOrdersInfo = async (payload: any) => {
    const payloadData = {
      orderData: selectedProducts,
      ...payload,
    };
    const { data, error } = await api.provide(
      "post",
      "/api/orders/createOrder",
      payloadData
    );
    if (error) throw error;
    return data;
  };

  const openSellerModale = async (payload: any) => {
    await updateOrdersInfo(payload);
  };

  return (
    <>
      <span style={{ fontWeight: "700", margin: "15px 0", fontSize: "16px" }}>
        Buyer Information
      </span>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="buyerName" label="Buyer Name">
              <AutoComplete
                className="custom-auto-complete"
                options={[{ value: "Buyer 1" }, { value: "Buyer 2" }]}
                placeholder="Select or enter buyer"
                filterOption={(inputValue, option) =>
                  option!.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item name="shippingMethod" label="Shipping Method">
              <Select placeholder="Select shipping method">
                <Select.Option value="standard">Standard</Select.Option>
                <Select.Option value="express">Express</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item name="shippingCost" label="Shipping Cost">
              <Input
                type="number"
                placeholder="Enter shipping cost"
                style={{ height: "40px", borderRadius: "8px" }}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="paymentDetail" label="Payment Details">
              <Select placeholder="Select payment method">
                <Select.Option value="creditCard">Credit Card</Select.Option>
                <Select.Option value="paypal">PayPal</Select.Option>
                <Select.Option value="bankTransfer">
                  Bank Transfer
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Form.Item
            label="Phone Number"
            style={{ marginBottom: 0, padding: "0 8px" }}
          >
            <Form.Item
              name="phonePrefix"
              style={{ display: "inline-block", width: "25%" }}
            >
              <Select style={{ borderRadius: "5px" }}>
                <Option value="1">+1</Option>
                <Option value="91">+91</Option>
                <Option value="44">+44</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              rules={[
                { required: true, message: "Please enter the phone number" },
              ]}
              style={{ display: "inline-block", width: "75%" }}
            >
              <Input
                placeholder="Enter phone number"
                style={{ borderRadius: "8px", height: "40px" }}
              />
            </Form.Item>
          </Form.Item>
          <p
            style={{
              position: "relative",
              top: "38px",
              margin: "0 10px",
              color: "rgba(116, 116, 116, 1)",
            }}
          >
            Or
          </p>
          <Col span={6}>
            <Form.Item
              name="buyerEmail"
              label="Buyer Email"
              rules={[
                { required: true, message: "Please enter the buyer email" },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input
                placeholder="Enter buyer email"
                style={{ borderRadius: "8px", height: "40px" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

interface EditOrderModaleProps {
  isModalVisible: boolean;
  setIsModalVisible: () => void;
  selectedProducts: any[];
  form: any;
  handleProductModal: any;
}
export default function EditOrderModale({
  isModalVisible,
  setIsModalVisible,
  form,
  handleProductModal,
  selectedProducts,
}: EditOrderModaleProps) {
  const [editBuyer, setEditBuyer] = useState<boolean>(false);
  const [buyerOrder, setBuyerORder] = useState<any[]>();
  const { isMobile } = useAppSelector((state) => state.AppReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);
  const dispatch = useDispatch();

  const handleCancel = () => {
    setIsModalVisible();
  };
  const handleEditBuyer = () => {
    setEditBuyer(true);
  };

  const handleDelete = (product: any) => {
    dispatch(removeSelectedProduct(product.id, false));
    if (selectedProducts.length === 1) {
      handleCancel();
    }
  };

  const showModale = () => {
    setEditBuyer(false);
  };

  const truncateString = (string: any, maxLength: number) => {
    if (string.length > maxLength) {
      return string.substring(0, maxLength) + "...";
    } else {
      return string;
    }
  };
  // useEffect(() => {
  //   setBuyerORder(order);
  // }, [buyerOrder]);
  const totalPrice = selectedProducts.reduce((acc, order) => {
    const price = parseFloat(order.price);
    return acc + (isNaN(price) ? 0 : price);
  }, 0);
  const totalCost = selectedProducts.reduce((acc, order) => {
    const price = parseFloat(order.cost);
    return acc + (isNaN(price) ? 0 : price);
  }, 0);
  return (
    <>
      {editBuyer && (
        <EditBuyerModale
          isModalVisible={editBuyer}
          selectedOrders={selectedProducts}
          order={buyerOrder}
          setIsBuyerModaleVisible={showModale}
        />
      )}
      <CustomModal
        title={"Order Items"}
        visible={isModalVisible}
        onCancel={() => handleCancel()}
        width={"100%"}
        footer={[
          // eslint-disable-next-line react/jsx-key
          <div style={{ display: "flex" }}>
            <Button
              key="back"
              type="primary"
              style={{
                padding: "8px 40px",
                height: 52,
                borderRadius: "8px",
                border: "1px solid #DDDDDD",
                color: "black",
                background: "#F4F4F4",
              }}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              style={{
                padding: "8px 40px",
                height: 52,
                borderRadius: "8px",
                background: "black",
                right: "2%",
                position: "absolute",
                fontWeight: 500,
                color: "white",
              }}
              onClick={() => handleProductModal(selectedProducts)}
            >
              Next
            </Button>
          </div>,
        ]}
      >
        {/* <span style={{ fontWeight: "700" }}>Order Items</span> */}
        {isMobile ? (
          <>
            <div style={{ margin: "20px 0" }}>
              <Row
                style={{
                  background: "#F9FAFB",
                  border: "1px solid #DDDDDD",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                  padding: "1rem",
                }}
              >
                <Col span={16} style={{ fontWeight: "500", color: "#2E2E2E" }}>
                  Inventory
                </Col>
                {/* <Col
                span={4}
                style={{
                  fontWeight: "500",
                  color: "#2E2E2E",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Price
              </Col>
              <Col
                span={4}
                style={{
                  fontWeight: "500",
                  color: "#2E2E2E",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Cost
              </Col> */}
              </Row>
              {selectedProducts.map((order, key) => (
                <Row
                  key={key}
                  style={{
                    width: "100%",
                    border: "1px solid rgba(221, 221, 221, 1)",
                    padding: "10px",
                    gap: "10px",
                  }}
                >
                  <Row key={key} style={{ width: "100%" }}>
                    <Col
                      style={{
                        fontWeight: "500",
                        color: "#2E2E2E",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <img
                        src={order.product.image}
                        alt=""
                        style={{ height: "61px", width: "61px" }}
                      />
                      <div>
                        <div>
                          <p style={{ marginBottom: "0" }}>
                            {truncateString(order.product.title, 20)}
                          </p>
                        </div>
                        <p
                          style={{
                            color: "rgba(152, 152, 152, 1)",
                            fontWeight: 400,
                          }}
                        >
                          Size: {order.option1Value}
                        </p>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          textAlign: "right",
                          justifyItems: "right",
                        }}
                      >
                        <div
                          style={{
                            padding: "4px 8px",
                            background: "rgba(249, 249, 249, 1)",
                            borderRadius: "5px",
                            border: "1px solid rgba(221, 221, 221, 1)",
                          }}
                        >
                          <DeleteOutlined onClick={() => handleDelete(order)} />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ justifyContent: "space-around" }}>
                    <Col
                      span={4}
                      style={{
                        fontWeight: "500",
                        color: "#2E2E2E",
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "20px",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 400,
                          color: "rgba(46, 46, 46, 0.8)",
                        }}
                      >
                        Price{" "}
                      </p>
                      <p
                        style={{
                          border: "1px #E4E4E7 solid",
                          padding: "3px 25px",
                          borderRadius: "5px",
                        }}
                      >
                        {getSymbolFromCurrency(store.currency)}
                        {order.price}
                      </p>
                    </Col>
                    <Col
                      span={4}
                      style={{
                        fontWeight: "500",
                        color: "#2E2E2E",
                        display: "flex",
                        paddingRight: "20px",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 400,
                          color: "rgba(46, 46, 46, 0.8)",
                        }}
                      >
                        Cost
                      </p>
                      <p
                        style={{
                          border: "1px #E4E4E7 solid",
                          padding: "3px 25px",
                          borderRadius: "5px",
                        }}
                      >
                        {getSymbolFromCurrency(store.currency)}
                        {order.cost}
                      </p>
                    </Col>
                  </Row>
                </Row>
              ))}

              <Row
                style={{
                  background: "#FFFFFF",
                  border: "1px solid #DDDDDD",
                  borderBottomRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  padding: "1rem",
                }}
              >
                <Col span={16} style={{ fontWeight: "500", color: "black" }}>
                  Total Price
                </Col>
                <Col
                  span={4}
                  style={{
                    fontWeight: "600",
                    color: "#2E2E2E",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {totalPrice}
                </Col>
                <Col
                  span={4}
                  style={{
                    fontWeight: "500",
                    color: "black",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {totalCost}
                </Col>
              </Row>
            </div>
            <>
              <span
                style={{
                  fontWeight: "700",
                  margin: "15px 0",
                  fontSize: "16px",
                }}
              >
                Buyer Information
              </span>
              <Form form={form} layout="vertical" onFinish={handleProductModal}>
                <Row>
                  <Col span={24}>
                    <Form.Item name="buyerName" label="Buyer Name">
                      <AutoComplete
                        className="custom-auto-complete"
                        options={[{ value: "Buyer 1" }, { value: "Buyer 2" }]}
                        placeholder="Select or enter buyer"
                        filterOption={(inputValue, option) =>
                          option!.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        style={{ height: "40px", borderRadius: "8px" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ gap: "20px" }}>
                  <Col span={11}>
                    <Form.Item name="shippingMethod" label="Shipping Method">
                      <Select
                        placeholder="Select shipping method"
                        style={{ height: "40px", borderRadius: "8px" }}
                      >
                        <Select.Option value="standard">Standard</Select.Option>
                        <Select.Option value="express">Express</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={11}>
                    <Form.Item name="shippingCost" label="Shipping Cost">
                      <Input
                        type="number"
                        placeholder="Enter shipping cost"
                        style={{ height: "40px", borderRadius: "8px" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name="paymentDetail" label="Payment Details">
                      <Select
                        placeholder="Select payment method"
                        style={{ height: "40px", borderRadius: "8px" }}
                      >
                        <Select.Option value="creditCard">
                          Credit Card
                        </Select.Option>
                        <Select.Option value="paypal">PayPal</Select.Option>
                        <Select.Option value="bankTransfer">
                          Bank Transfer
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Phone Number"
                      style={{ marginBottom: 0, padding: "0 8px" }}
                    >
                      <Form.Item
                        name="phonePrefix"
                        style={{
                          display: "inline-block",
                          width: "18%",
                          marginRight: "10px",
                        }}
                      >
                        <Select style={{ borderRadius: "5px" }}>
                          <Option value="1">+1</Option>
                          <Option value="91">+91</Option>
                          <Option value="44">+44</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="phoneNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the phone number",
                          },
                        ]}
                        style={{ display: "inline-block", width: "78%" }}
                      >
                        <Input
                          placeholder="Enter phone number"
                          style={{ borderRadius: "8px", height: "40px" }}
                        />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="buyerEmail"
                      label="Buyer Email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the buyer email",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email address",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter buyer email"
                        style={{ borderRadius: "8px", height: "40px" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </>
          </>
        ) : (
          <>
            <Row
              style={{
                background: "#F9FAFB",
                border: "1px solid #DDDDDD",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
                padding: "1rem",
              }}
            >
              <Col span={16} style={{ fontWeight: "500", color: "#2E2E2E" }}>
                Inventory
              </Col>
              <Col
                span={4}
                style={{
                  fontWeight: "500",
                  color: "#2E2E2E",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Price
              </Col>
              <Col
                span={4}
                style={{
                  fontWeight: "500",
                  color: "#2E2E2E",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Cost
              </Col>
            </Row>
            {selectedProducts.map((order, key) => (
              <Row
                key={key}
                style={{
                  background: "#FFFFFF",
                  border: "1px solid #DDDDDD",
                  padding: "1rem",
                }}
              >
                <Col
                  span={15}
                  style={{
                    fontWeight: "500",
                    color: "#2E2E2E",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={order.product.image}
                    alt=""
                    style={{ height: "61px", width: "61px" }}
                  />
                  <p>{order.product.title}</p>
                </Col>
                <Col
                  span={4}
                  style={{
                    fontWeight: "500",
                    color: "#2E2E2E",
                    display: "flex",
                    alignItems: "center",
                    paddingRight: "20px",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      border: "1px #E4E4E7 solid",
                      padding: "3px 25px",
                      borderRadius: "5px",
                    }}
                  >
                    {order.price}
                  </p>
                </Col>
                <Col
                  span={4}
                  style={{
                    fontWeight: "500",
                    color: "#2E2E2E",
                    display: "flex",
                    paddingRight: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      border: "1px #E4E4E7 solid",
                      padding: "3px 25px",
                      borderRadius: "5px",
                    }}
                  >
                    {order.cost}
                  </p>
                </Col>
              </Row>
            ))}

            <Row
              style={{
                background: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderBottomRightRadius: "10px",
                borderBottomLeftRadius: "10px",
                padding: "1rem",
              }}
            >
              <Col span={16} style={{ fontWeight: "500", color: "black" }}>
                Total Price
              </Col>
              <Col
                span={4}
                style={{
                  fontWeight: "600",
                  color: "#2E2E2E",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {totalPrice}
              </Col>
              <Col
                span={4}
                style={{
                  fontWeight: "500",
                  color: "black",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {totalCost}
              </Col>
            </Row>
          </>
        )}
      </CustomModal>
    </>
  );
}
