//
import React, { useEffect, useState } from "react";
import { CustomModal, SearchDiv } from "./inventoryStyle";
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Collapse,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  PlusOutlined,
  CheckOutlined,
  DownOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import ProductList from "./ProductList";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { InventoryState } from "../../redux/reducers/InventoryReducer";
import { SearchBox } from "../../components/Search/Search";
import {
  addSelectedProduct,
  clearSelectedProduct,
  getProducts,
  removeSelectedProduct,
  updateProduct,
  updateSelectedProduct,
} from "../../redux/actions/productActions";
import {
  createDraftOrderFromAPI,
  getOrders,
  getOrder,
  createOrder,
} from "../../redux/actions/orderActions";
import ProductModal from "./ProductModal";
import AddCustomProduct from "./AddCustomProduct";
import styled from "styled-components";
import getSymbolFromCurrency from "currency-symbol-map";
const { Option } = Select;
import EditOrderModale, { OrderForm } from "./EditOrderModale";
import {
  InventoryCardMobileView,
  ProductRowMobile,
  ProductRowMobileTop,
} from "../../components/Common/InventoryCardView";
import { Footer } from "antd/lib/layout/layout";
import { useForm } from "antd/lib/form/Form";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { getPaginatedInventories } from "../../redux/actions/inventoryActions";
import { createInvoice } from "../../redux/actions/invoiceActions";

const Container = styled.div`
  // padding: 0 16px;
  display: flex;
  flex-direction: column;
  #inventoryFilter {
    margin: 0 0 0 15px;
    flex-wrap: nowrap;
    &.ant-form-inline .ant-form-item:last-of-type {
      margin-right: 0;
    }
  }
  .ant-form-item-label label {
    color: rgba(116, 116, 116, 1);
    font-weight: 500;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    height: 40px;
    color: #292d32;
    border-radius: 5px;
    border: 1px solid var(--Line, #ddd);
    background: var(--White, #fff);
    align-items: center;
  }
  table {
    thead {
      .ant-checkbox-inner {
        background-color: transparent; /* Customize the check color */
      }
    }
  }
  @media (max-width: 768px) {
    background-color: #fff;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;
  @media (max-width: 768px) {
    border-radius: 4px;
    order: 2;
  }
`;

const ProductRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
const ProductDetails = styled.div`
  flex: 1;
  padding: 10px;
  padding-left: 70px;
  border-bottom: 0.5px solid var(--Line, #ddd);
  gap: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  .ant-checkbox {
    height: 16px;
  }
  &.pricing {
    & > div {
      &:not(.Requested) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &.center {
    align-items: center;
  }
  &:first-of-type {
    border-left: 1px solid #ddd;
  }
  b {
    align-self: stretch;
    flex: 1;
    a {
      color: inherit;
      white-space: nowrap;
    }
  }
  & > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 20px;
    // justify-content: space-between;
    flex-wrap: nowrap;
    span {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      p {
        margin: 0 0 3px;
        color: #292d32;
      }
      label {
        color: var(--Text---Grey, #989898);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }
  @media (max-width: 768px) {
    border-bottom: 1px solid #ddd;
    border-right: none;
    font-size: 10px;
    b {
      font-size: inherit;
    }
    & > div {
      font-size: inherit;
      gap: 16px;
      span {
        justify-content: flex-start;
        font-size: inherit;
        label {
          font-size: inherit;
        }
      }
    }
    &.center {
      align-items: flex-start;
    }
  }
`;
const SkuDivMargin = styled.div`
  margin-bottom: 1.4rem;
`;

const QuantityInput = styled.div`
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 35px;
  align-items: center;
  button {
    border: none;
    border-radius: 5px;
    padding: 4px 10px;
  }
  .ant-input-number {
    border: none;
    width: 38px;
    .ant-input-number-input {
      text-align: center;
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  background-color: white;
  border: 1px solid rgba(221, 221, 221, 1);
  borderradius: 5px;
  .ant-collapse-header {
    padding: 10px !important;
  }
  .ant-collapse-content-active {
    border-top: 0.5px solid var(--Line, #ddd) !important;
    // padding-left: 40px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse-expand-icon {
    align-self: center;
  }
`;
interface Product {
  id: string;
  image: string;
  title: string;
  sku: string;
  storeRequestPriceRange?: [number, number];
  price: number;
  requestedPrice?: number;
  cost?: number;
  option1Value?: string;
}
interface AddInventoryModalProp {
  isModalVisible: boolean;
  setIsModalVisible: () => void;
}

const CollapseHeader = (record: any) => {
  const { activeKey, image, title, keyItem, quantity, sku, inventories } =
    record;
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            padding: "10px",
            background: "white",
            marginRight: "10px",
          }}
        >
          <img src={image} width={52.5} style={{ objectFit: "contain" }} />
        </div>
        <div style={{ flex: "1" }}>
          <label style={{ color: "rgba(152, 152, 152, 1)", fontWeight: "500" }}>
            Name
          </label>
          <p
            style={{
              fontWeight: "600",
              fontSize: "13px",
              marginTop: "4px",
              lineHeight: "1rem",
            }}
          >
            {title}
          </p>
        </div>
      </div>
    </>
  );
};

export default function CreateOrderModale({
  isModalVisible,
  setIsModalVisible,
}: AddInventoryModalProp) {
  const { store } = useAppSelector((state) => state.StoreReducer);
  const { products, updatedProduct, productsLoading, selectedProducts } =
    useAppSelector((state) => state.ProductReducer);
  const {
    inventoriesToReview,
    paginatedInventories,
    inventoriesLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const [inventoriesToAdd, setInventoriesToAdd] = useState<any[]>(
    inventoriesToReview ?? []
  );
  const [search, setSearch] = useState("");
  const [productModal, setProductModal] = useState<boolean>(false);
  const [customModal, setCustomModal] = useState<boolean>(false);
  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [editOrder, setEditOrder] = useState<boolean>(false);
  const [groupedData, setGroupedData] = useState<any[]>([]);
  const [orders, setOrders] = useState<any[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableNestedView, setTableNestedView] = useState(false);
  const [order, setOrder] = useState<any>();
  const dispatch = useAppDispatch();
  const [orderLoading, setOrderLoading] = useState(false);
  const [form] = useForm();
  const { isMobile } = useAppSelector((state) => state.AppReducer);
  const [selectedInv, setSelectedInv] = useState<any[]>([]);
  const [filters, setFilters] = useState<any>({
    status: "Active",
    printed: undefined,
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: undefined,
    sortOption: "newestUpdated",
    consigner: undefined,
  });
  const addToInventories = (items: any[]) => {
    setInventoriesToAdd([...inventoriesToAdd, ...items]);
  };
  const handleCancel = async () => {
    await dispatch(clearSelectedProduct());
    setSelectedInv([]);
    setTimeout(() => {
      setEditOrder(false);
      setIsModalVisible();
    }, 1000);
  };

  const handleClearAll = async () => {
    await dispatch(clearSelectedProduct());
    setSelectedInv([]);
  };

  // useEffect(() => {
  //   reloadInventories();
  // }, []);

  const reloadInventories = async () => {
    await dispatch(
      getPaginatedInventories(
        search,
        filters,
        currentPage,
        pageSize,
        tableNestedView
      )
    );
  };

  function generateTrackingId(): string {
    const timestamp = Date.now().toString(); // Current timestamp in milliseconds
    const randomString = Math.random().toString(36).substring(2, 10); // Random string
    return `#INV-${timestamp}-${randomString}`;
  }

  const handleProductModal = async (values: any) => {
    console.log(values, "values------------------------");

    const formData = form.getFieldsValue();
    console.log(formData, "formData------------------------");

    if (selectedProducts.length === 0) {
      message.error("Please select at least one product");
      return;
    }

    const isAllQuantitiesValid = selectedProducts.every((i) => i.quantity > 0);

    if (Object.keys(formData).length === 0) {
      if (!isAllQuantitiesValid) {
        message.error(
          "Please add quantity to selected products or remove those with invalid quantity"
        );
        return;
      }

      // setSelectedOrder(selectedProducts);
      setEditOrder(true); // Flag to open edit modal
      return;
    }

    try {
      setOrderLoading(true);
      const trackingCode = generateTrackingId();
      const { invoice } = await dispatch(
        createInvoice({
          trackingCode,
          invoiceDate: new Date().toISOString(),
          isSeller: false,
        })
      );
      const orderProducts = selectedProducts.map((i: any) => ({
        ...i,
        invoiceId: invoice.id,
      }));
      const payload = {
        ordersData: orderProducts,
        buyerName: formData.buyerName,
        buyerEmail: formData.buyerEmail,
        paymentDetail: formData.paymentDetails,
        phoneNumber: `${formData.phonePrefix}${formData.phoneNumber}`,
        shippingCost: formData.shippingCost,
        shippingMethod: formData.shippingMethod,
        isSeller: false,
      };

      const response = await dispatch(createOrder(payload));

      setOrders(response.order);
      setOrder(response.order);

      setOrderLoading(false);
      form.resetFields();
      await dispatch(clearSelectedProduct());
      setIsModalVisible(); // Close modal
      message.success("Order created successfully!");
      setEditOrder(false); // Reset edit mode
    } catch (error) {
      console.error("Order creation failed", error);
      message.error("Failed to create order");
    }
  };

  const handleCustomModal = () => {
    setIsModalVisible();
    setCustomModal(true);
  };
  const handleAddProductModal = () => {
    setProductModal(false);
  };
  const handleAddCustomProductModal = () => {
    setCustomModal(false);
  };
  const handleAddCustomProductModalSuccess = () => {
    setCustomModal(false);
    setProductModal(true);
  };

  useEffect(() => {
    if (search) {
      dispatch(getProducts(search));
    }
  }, [search]);

  useEffect(() => {
    if (isModalVisible) {
      reloadInventories();
    }
  }, [isModalVisible]);

  // const increment = (record: any) => {
  //   const newValue = record.quantity + 1;
  //   // setValue(newValue);
  //   handleChange(newValue, record, "quantity");
  // };

  // const decrement = (record: any) => {
  //   const newValue = record.quantity > 1 ? record.quantity - 1 : 1; // Prevent going below 1
  //   // setValue(newValue);
  //   handleChange(newValue, record, "quantity");
  // };

  useEffect(() => {
    if (paginatedInventories?.rows?.length) {
      const groupedData = paginatedInventories?.rows?.reduce((groups, item) => {
        const groupId = `${item.product?.sku}`;

        // Check if the group already exists
        if (!groups[groupId]) {
          groups[groupId] = {
            productId: item.product?.sku,
            key: groupId,
            productTitle: item?.product?.title,
            productImage: item.product?.image,
            productSku: item.product?.sku,
            consigner: item?.consigner,
            quantity: 0,
            inventories: [], // Create an empty inventories array
          };
        }

        // Find the inventory in the inventories array by option1Value
        let existingInventory = groups[groupId].inventories.find(
          (inventoryItem) => inventoryItem.option1Value === item.option1Value
        );

        if (existingInventory) {
          // If an inventory with the same option1Value exists, push the new item into the `itemArray`
          existingInventory.itemArray.push(item);
        } else {
          // If it doesn't exist, create a new inventory item with `itemArray`
          groups[groupId].inventories.push({
            ...item,
            itemArray: [item], // Start the itemArray with the current item
          });
        }

        // Increment the total quantity for the group
        groups[groupId].quantity += item.quantity; // Add the item's quantity
        return groups;
      }, {});

      console.log(groupedData, "=========groupedData========");
      setGroupedData(Object.values(groupedData));
    }
  }, [paginatedInventories]);

  const toggleSelection = (product: any) => {
    const isProductSelected = selectedProducts.some((p) => p.id === product.id);
    if (isProductSelected) {
      dispatch(removeSelectedProduct(product.id, false));
    } else {
      dispatch(addSelectedProduct(product));
    }
  };

  const OrderToggleSelection = (product: any) => {
    const isProductSelected = selectedProducts.some((p) => p.id === product.id);
    if (isProductSelected) {
      dispatch(removeSelectedProduct(product.id, false));
    } else {
      const productData = {
        ...product.itemArray[0],
        quantity: 1,
      };
      setSelectedInv((prevSelectedInv) => {
        const existingProductIndex = prevSelectedInv.findIndex(
          (inv) =>
            inv.product.sku === product.product.sku &&
            inv.option1Value === product.option1Value
        );
        if (existingProductIndex !== -1) {
          // If the product already exists, return the same array
          return prevSelectedInv;
        }
        // If the product doesn't exist, add it
        return [...prevSelectedInv, { ...product }];
      });
      dispatch(addSelectedProduct(productData));
    }
  };

  const handleIncrement = (product: any) => {
    const isProductSelected = selectedProducts?.filter(
      (i) =>
        i?.product?.sku === product?.product?.sku &&
        i?.option1Value === product?.option1Value
    );
    if (
      isProductSelected.length &&
      isProductSelected.length < product.itemArray.length
    ) {
      const productData = { ...product.itemArray[isProductSelected.length] };
      dispatch(addSelectedProduct(productData));
      // Add the current product to the selectedInv array without duplicates
      setSelectedInv((prevSelectedInv) => {
        const existingProductIndex = prevSelectedInv.findIndex(
          (inv) =>
            inv.product.sku === product.product.sku &&
            inv.option1Value === product.option1Value
        );
        if (existingProductIndex !== -1) {
          // If the product already exists, return the same array
          return prevSelectedInv;
        }
        // If the product doesn't exist, add it
        return [...prevSelectedInv, { ...product }];
      });
    } else if (!isProductSelected.length) {
      const productData = { ...product.itemArray[0] };
      dispatch(addSelectedProduct(productData));
      // Add the current product to the selectedInv array without duplicates
      setSelectedInv((prevSelectedInv) => {
        const existingProductIndex = prevSelectedInv.findIndex(
          (inv) =>
            inv.product.sku === product.product.sku &&
            inv.option1Value === product.option1Value
        );
        if (existingProductIndex !== -1) {
          // If the product already exists, return the same array
          return prevSelectedInv;
        }
        // If the product doesn't exist, add it
        return [...prevSelectedInv, { ...product }];
      });
    }
  };

  const handleDecrement = (product: any) => {
    const isProductSelected = selectedProducts?.filter(
      (i) =>
        i?.product?.sku === product?.product?.sku &&
        i?.option1Value === product?.option1Value
    );
    if (isProductSelected.length) {
      dispatch(
        removeSelectedProduct(
          product.itemArray[isProductSelected.length - 1].id,
          false
        )
      );
      if (isProductSelected.length === 1) {
        setSelectedInv((prevSelectedInv) => {
          // Find the product in selectedInv
          const updatedInv = prevSelectedInv.filter(
            (inv) =>
              !(
                inv.product.sku === product.product.sku &&
                inv.option1Value === product.option1Value
              )
          );
          return updatedInv;
        });
      }
    }
  };

  const handleDelete = (product: any) => {
    const isProductSelected = selectedProducts?.filter(
      (i) =>
        i?.product?.sku === product?.product?.sku &&
        i?.option1Value === product?.option1Value
    );
    isProductSelected.map((i) => dispatch(removeSelectedProduct(i.id, false)));
    setSelectedInv((prevSelectedInv) => {
      // Find the product in selectedInv
      const updatedInv = prevSelectedInv.filter(
        (inv) =>
          !(
            inv.product.sku === product.product.sku &&
            inv.option1Value === product.option1Value
          )
      );
      return updatedInv;
    });
  };

  const isSelected = selectedProducts.some(
    (p) => p.id === paginatedInventories?.rows
  );

  const handleCategoryChange = async (productId: any, value: any) => {
    //         const selectedOption = productTemplates.find(
    //   (option) => option.id === templateId
    // );
    setSelectedCategory((prevSelectedCategory) => ({
      ...prevSelectedCategory,
    }));

    try {
      const updatedProductData = { ...value };
      const response = await dispatch(
        updateProduct(productId, updatedProductData)
      );
      const productsToUpdate = selectedProducts.find((p) => p.id === productId);
      const updatedProduct = {
        ...productsToUpdate,
        productId: response?.id,
        storeId: response?.storeId,
        productTemplateId: response?.productTemplateId,
      };
      dispatch(updateSelectedProduct(updatedProduct));
    } catch (error) {
      console.error("Dispatch error:", error);
    }
  };
  // useEffect(() => {}, [paginatedInventories?.rows]);
  const showModal = () => {
    setEditOrder(false);
  };

  const truncateString = (string: any, maxLength: number) => {
    if (string.length > maxLength) {
      return string.substring(0, maxLength) + "...";
    } else {
      return string;
    }
  };

  const findLength = (inventories) => {
    const groupedInventories = inventories.reduce((acc, inventory) => {
      const key = inventory.option1Value;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(inventory);
      return acc;
    }, {});

    console.log(groupedInventories, "==========groupedInventories===========");

    // Find the length of inventories that have matches (group length > 1)
    const matchedInventories = Object.values(groupedInventories).filter(
      (group: any) => group.length > 1
    );

    return matchedInventories.flat().length; // Flatten the matched groups and get total count
  };

  const groupedProducts = selectedProducts.reduce((groups, product) => {
    const key = `${product?.product?.sku}-${product?.option1Value}`;
    if (!groups[key]) {
      groups[key] = { ...product, count: 0 };
    }
    groups[key].count += 1; // Track how many times this combination appears
    return groups;
  }, {});

  const totalPrice = Object.values(groupedProducts).reduce(
    (acc: any, product: any) => {
      const price = parseFloat(product.price) * product.count; // Multiply by the count
      return acc + (isNaN(price) ? 0 : price);
    },
    0
  );

  const totalCost = Object.values(groupedProducts).reduce(
    (acc: any, product: any) => {
      const cost = parseFloat(product.cost) * product.count; // Multiply by the count
      return acc + (isNaN(cost) ? 0 : cost);
    },
    0
  );

  const totalQuantity = selectedProducts.length;

  return (
    <>
      {editOrder && isMobile && (
        <EditOrderModale
          selectedProducts={selectedProducts}
          isModalVisible={editOrder}
          setIsModalVisible={showModal}
          form={form}
          handleProductModal={handleProductModal}
        />
      )}
      {customModal && (
        <AddCustomProduct
          setIsCustomModal={handleAddCustomProductModal}
          setIsSucces={handleAddCustomProductModalSuccess}
          isProductModal={customModal}
        />
      )}
      <CustomModal
        title="Add Inventories"
        visible={isModalVisible && isMobile}
        onCancel={() => handleCancel()}
        width={"100%"}
        footer={[
          // eslint-disable-next-line react/jsx-key
          <div style={{ display: "flex" }}>
            <div style={{ textAlign: "left" }}>
              <p>{selectedProducts.length} Selected Products</p>
              <a
                key="back"
                type="primary"
                style={{
                  color: "black",
                  textDecoration: "underline",
                  fontWeight: "500",
                }}
                onClick={() => handleClearAll()}
              >
                Clear All
              </a>
            </div>
            <Button
              key="submit"
              type="primary"
              style={{
                padding: "8px 10px",
                height: 52,
                borderRadius: "8px",
                background: "black",
                right: "2%",
                color: "white",
                position: "absolute",
                fontWeight: "500",
              }}
              onClick={() => handleProductModal(selectedProducts)}
            >
              Add to Order
            </Button>
          </div>,
        ]}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            margin: "20px 0",
          }}
        >
          <SearchDiv style={{ flexGrow: 1, marginRight: "10px" }}>
            <SearchBox
              //   value={}
              //   onChange={(e) => setSearch(e)}
              placeholder={"Search Products"}
            />
          </SearchDiv>
          <Button
            key="submit"
            type="primary"
            style={{
              padding: "8px 10px",
              height: 52,
              width: 162,
              border: "1px solid ",
              color: "black",
              borderRadius: "8px",
              backgroundColor: "rgba(104, 95, 249, 0.1)",
            }}
            onClick={() => handleCustomModal()}
          >
            Add Custom Item
          </Button>
        </div>
        <Container>
          <Wrapper>
            {inventoriesLoading ? (
              <>
                <Spin />
              </>
            ) : (
              paginatedInventories.rows.map((inventory, key) => (
                <ProductRowMobile key={key}>
                  <input
                    type="checkbox"
                    style={{
                      position: "absolute",
                      zIndex: "999",
                      padding: "5px",
                      // backgroundColor: isSelected ? "black" : "transparent",
                      accentColor: "black",
                    }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the parent click event
                      toggleSelection(inventory);
                    }}
                    onChange={() =>
                      handleCategoryChange(inventory.id, inventory)
                    }
                  />
                  <ProductRowMobileTop>
                    <Image
                      src={inventory.product?.image}
                      width={77}
                      alt=""
                      style={{ flex: "0 0 77px" }}
                    />
                    <div
                      style={{
                        flex: 1,
                        flexDirection: "column",
                        fontSize: 12,
                      }}
                    >
                      <b style={{ flex: 1 }}>
                        {truncateString(inventory.product?.title, 22)}
                      </b>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 16,
                            flex: 1,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              flex: 1,
                            }}
                          >
                            <b
                              style={{
                                color: "rgba(116, 116, 116, 1)",
                              }}
                            >
                              {inventory.option1Value}
                            </b>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              flex: 1,
                            }}
                          >
                            <b
                              style={{
                                color: "rgba(116, 116, 116, 1)",
                              }}
                            >
                              {inventory.option2Value}
                            </b>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              flex: 1,
                            }}
                          >
                            <b
                              style={{
                                color: "rgba(116, 116, 116, 1)",
                              }}
                            >
                              {inventory.product?.sku}
                            </b>
                          </div>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 16,
                            flex: 1,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "5px",
                              flex: 1,
                            }}
                          >
                            <label>Price: </label>
                            <b
                              style={{
                                color: "rgba(116, 116, 116, 1)",
                              }}
                            >
                              {getSymbolFromCurrency(store.currency)}
                              {inventory.price}
                            </b>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "5px",
                              flex: 1,
                            }}
                          >
                            <label>Cost: </label>
                            <b
                              style={{
                                color: "rgba(116, 116, 116, 1)",
                              }}
                            >
                              {getSymbolFromCurrency(store.currency)}
                              {inventory.cost}
                            </b>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "5px",
                              flex: 1,
                            }}
                          >
                            <label>Profit: </label>
                            <b
                              style={{
                                color: "rgba(116, 116, 116, 1)",
                              }}
                            >
                              {getSymbolFromCurrency(store.currency)}
                              {inventory.cost}
                            </b>
                          </div>
                        </span>
                      </div>
                    </div>
                  </ProductRowMobileTop>
                </ProductRowMobile>
              ))
            )}
          </Wrapper>
        </Container>
      </CustomModal>
      <Drawer
        title="Add Order"
        placement="right"
        closable={false}
        onClose={() => setIsModalVisible()}
        visible={isModalVisible && !isMobile}
        width="60%"
        footer={[
          // eslint-disable-next-line react/jsx-key
          <div style={{ display: "flex" }}>
            <Button
              key="back"
              type="primary"
              style={{
                padding: 8,
                width: 90,
                height: 52,
                borderRadius: "8px",
                border: "1px solid #DDDDDD",
                color: "black",
                background: "#F4F4F4",
              }}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              style={{
                padding: "8px 10px",
                height: 52,
                borderRadius: "8px",
                background: "black",
                right: "2%",
                position: "absolute",
              }}
              loading={orderLoading}
              onClick={() => handleProductModal(selectedProducts)}
            >
              Create Order {`(${selectedProducts.length})`}
            </Button>
          </div>,
        ]}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <SearchDiv style={{ flexGrow: 1, marginRight: "10px" }}>
            <SearchBox
              //   value={}
              //   onChange={(e) => setSearch(e)}
              placeholder={"Search Products"}
            />
          </SearchDiv>
          <Button
            key="submit"
            type="primary"
            style={{
              padding: "8px 10px",
              height: 52,
              width: 162,
              border: "1px solid ",
              color: "black",
              borderRadius: "8px",
              backgroundColor: "rgba(104, 95, 249, 0.1)",
            }}
            onClick={() => handleCustomModal()}
          >
            Add Custom Item
          </Button>
        </div>
        <Container>
          <Wrapper>
            {inventoriesLoading ? (
              <>
                <Spin />
              </>
            ) : (
              <>
                {editOrder ? (
                  <>
                    <span
                      style={{
                        fontWeight: "700",
                        marginBottom: "15px",
                        fontSize: "16px",
                      }}
                    >
                      Order Items
                    </span>
                    <Row
                      style={{
                        background: "#F9FAFB",
                        border: "1px solid #DDDDDD",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                        padding: "1rem",
                      }}
                    >
                      <Col
                        span={10}
                        style={{ fontWeight: "500", color: "#2E2E2E" }}
                      >
                        Inventory
                      </Col>
                      <Col
                        span={4}
                        style={{
                          fontWeight: "500",
                          color: "#2E2E2E",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Quantity
                      </Col>
                      <Col
                        span={4}
                        style={{
                          fontWeight: "500",
                          color: "#2E2E2E",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Price
                      </Col>
                      <Col
                        span={4}
                        style={{
                          fontWeight: "500",
                          color: "#2E2E2E",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Cost
                      </Col>
                    </Row>
                    {selectedInv.map((product, key) => (
                      <Row
                        key={key}
                        style={{
                          background: "#FFFFFF",
                          border: "1px solid #DDDDDD",
                          padding: "1rem",
                        }}
                      >
                        <Col
                          span={9}
                          style={{
                            fontWeight: "500",
                            color: "#2E2E2E",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            src={product.product.image}
                            alt=""
                            style={{ height: "61px", width: "61px" }}
                          />
                          <div>
                            <p style={{ margin: "0" }}>
                              {product.product.title}
                            </p>
                            <p
                              style={{
                                color: "rgba(152, 152, 152, 1)",
                                fontWeight: 400,
                              }}
                            >
                              Size: {product.option1Value}
                            </p>
                          </div>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            fontWeight: "500",
                            color: "#2E2E2E",
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <QuantityInput>
                            <Button onClick={() => handleDecrement(product)}>
                              -
                            </Button>
                            <InputNumber
                              value={
                                selectedProducts?.filter(
                                  (i) =>
                                    i?.product?.sku === product?.product?.sku &&
                                    i?.option1Value === product?.option1Value
                                )?.length
                              }
                              // onChange={(value: any) =>
                              //   handleChange(
                              //     value,
                              //     record,
                              //     "quantity"
                              //   )
                              // }
                              defaultValue={0}
                              min={0}
                              controls={false}
                            />
                            <Button onClick={() => handleIncrement(product)}>
                              +
                            </Button>
                          </QuantityInput>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            fontWeight: "500",
                            color: "#2E2E2E",
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              border: "1px #E4E4E7 solid",
                              padding: "0 5px",
                              borderRadius: "5px",
                              color: "rgba(152, 152, 152, 1)",
                              height: "33px",
                              width: "80px",
                              alignContent: "center",
                              margin: 0,
                            }}
                          >
                            {getSymbolFromCurrency(store.currency)}
                            {product.price}
                          </p>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            fontWeight: "500",
                            color: "#2E2E2E",
                            display: "flex",
                            paddingRight: "20px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              border: "1px #E4E4E7 solid",
                              padding: "0 5px",
                              borderRadius: "5px",
                              color: "rgba(152, 152, 152, 1)",
                              height: "33px",
                              width: "80px",
                              alignContent: "center",
                              margin: 0,
                            }}
                          >
                            {getSymbolFromCurrency(store.currency)}
                            {product.cost}
                          </p>
                        </Col>
                        <Col
                          style={{
                            fontWeight: "500",
                            color: "#2E2E2E",
                            display: "flex",
                            paddingRight: "20px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              padding: "4px 8px",
                              background: "rgba(249, 249, 249, 1)",
                              borderRadius: "5px",
                              border: "1px solid rgba(221, 221, 221, 1)",
                            }}
                          >
                            <DeleteOutlined
                              onClick={() => handleDelete(product)}
                            />
                          </div>
                        </Col>
                      </Row>
                    ))}

                    <Row
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid #DDDDDD",
                        borderBottomRightRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        padding: "1rem",
                      }}
                    >
                      <Col span={9} style={{ fontWeight: "600" }}>
                        Total Price
                      </Col>
                      <Col
                        span={4}
                        style={{
                          fontWeight: "600",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        {totalQuantity}
                      </Col>
                      <Col
                        span={4}
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {getSymbolFromCurrency(store.currency)}
                        {totalPrice}
                      </Col>
                      <Col
                        span={4}
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {getSymbolFromCurrency(store.currency)}
                        {totalCost}
                      </Col>
                    </Row>
                    <>
                      <span
                        style={{
                          fontWeight: "700",
                          margin: "15px 0",
                          fontSize: "16px",
                        }}
                      >
                        Buyer Information
                      </span>
                      <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleProductModal}
                      >
                        <Row gutter={16}>
                          <Col span={8}>
                            <Form.Item name="buyerName" label="Buyer Name">
                              <AutoComplete
                                className="custom-auto-complete"
                                options={[
                                  { value: "Buyer 1" },
                                  { value: "Buyer 2" },
                                ]}
                                placeholder="Select or enter buyer"
                                filterOption={(inputValue, option) =>
                                  option!.value
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                                }
                              />
                            </Form.Item>
                          </Col>

                          <Col span={5}>
                            <Form.Item
                              name="shippingMethod"
                              label="Shipping Method"
                            >
                              <Select placeholder="Select shipping method">
                                <Select.Option value="standard">
                                  Standard
                                </Select.Option>
                                <Select.Option value="express">
                                  Express
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={5}>
                            <Form.Item
                              name="shippingCost"
                              label="Shipping Cost"
                            >
                              <Input
                                type="number"
                                placeholder="Enter shipping cost"
                                style={{ height: "40px", borderRadius: "5px" }}
                                prefix={getSymbolFromCurrency(store.currency)}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              name="paymentDetail"
                              label="Payment Details"
                            >
                              <Select placeholder="Select payment method">
                                <Select.Option value="creditCard">
                                  Credit Card
                                </Select.Option>
                                <Select.Option value="paypal">
                                  PayPal
                                </Select.Option>
                                <Select.Option value="bankTransfer">
                                  Bank Transfer
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Form.Item
                            label="Phone Number"
                            style={{
                              marginBottom: 0,
                              padding: "0 8px",
                              width: "34%",
                            }}
                          >
                            <Form.Item
                              name="phonePrefix"
                              style={{
                                display: "inline-block",
                                width: "20%",
                                marginRight: "10px",
                              }}
                            >
                              <Select style={{ borderRadius: "5px" }}>
                                <Option value="1">+1</Option>
                                <Option value="91">+91</Option>
                                <Option value="44">+44</Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name="phoneNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter the phone number",
                                },
                              ]}
                              style={{ display: "inline-block", width: "75%" }}
                            >
                              <Input
                                placeholder="Phone number"
                                style={{ borderRadius: "5px", height: "40px" }}
                              />
                            </Form.Item>
                          </Form.Item>
                          <p
                            style={{
                              position: "relative",
                              top: "38px",
                              margin: "0 10px",
                              color: "rgba(116, 116, 116, 1)",
                            }}
                          >
                            Or
                          </p>
                          <Col span={6}>
                            <Form.Item
                              name="buyerEmail"
                              label="Buyer Email"
                              rules={[
                                {
                                  required: false,
                                },
                                {
                                  type: "email",
                                  message: "Please enter a valid email address",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter buyer email"
                                style={{ borderRadius: "5px", height: "40px" }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  </>
                ) : (
                  <>
                    {isMobile ? (
                      paginatedInventories.rows.map((inventory, key) => (
                        <ProductRowMobile key={key}>
                          <input
                            type="checkbox"
                            style={{
                              position: "absolute",

                              padding: "5px",
                              backgroundColor: isSelected
                                ? "black"
                                : "transparent",
                            }}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the parent click event
                              toggleSelection(inventory);
                            }}
                            onChange={() =>
                              handleCategoryChange(inventory.id, inventory)
                            }
                          />
                          <ProductRowMobileTop>
                            <Image
                              src={inventory.product?.image}
                              width={77}
                              alt=""
                              style={{ flex: "0 0 77px" }}
                            />
                            <div
                              style={{
                                flex: 1,
                                flexDirection: "column",
                                fontSize: 12,
                              }}
                            >
                              <b style={{ flex: 1 }}>
                                {truncateString(inventory.product?.title, 22)}
                              </b>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 16,
                                    flex: 1,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      flex: 1,
                                    }}
                                  >
                                    <b
                                      style={{
                                        color: "rgba(116, 116, 116, 1)",
                                      }}
                                    >
                                      {inventory.option1Value}
                                    </b>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      flex: 1,
                                    }}
                                  >
                                    <b
                                      style={{
                                        color: "rgba(116, 116, 116, 1)",
                                      }}
                                    >
                                      {inventory.option2Value}
                                    </b>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      flex: 1,
                                    }}
                                  >
                                    <b
                                      style={{
                                        color: "rgba(116, 116, 116, 1)",
                                      }}
                                    >
                                      {inventory.product?.sku}
                                    </b>
                                  </div>
                                </span>
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 16,
                                    flex: 1,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "5px",
                                      flex: 1,
                                    }}
                                  >
                                    <label>Price: </label>
                                    <b
                                      style={{
                                        color: "rgba(116, 116, 116, 1)",
                                      }}
                                    >
                                      {getSymbolFromCurrency(store.currency)}
                                      {inventory.price}
                                    </b>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "5px",
                                      flex: 1,
                                    }}
                                  >
                                    <label>Cost: </label>
                                    <b
                                      style={{
                                        color: "rgba(116, 116, 116, 1)",
                                      }}
                                    >
                                      {getSymbolFromCurrency(store.currency)}
                                      {inventory.cost}
                                    </b>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "5px",
                                      flex: 1,
                                    }}
                                  >
                                    <label>Profit: </label>
                                    <b
                                      style={{
                                        color: "rgba(116, 116, 116, 1)",
                                      }}
                                    >
                                      {getSymbolFromCurrency(store.currency)}
                                      {inventory.cost}
                                    </b>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </ProductRowMobileTop>
                        </ProductRowMobile>
                      ))
                    ) : (
                      <>
                        <StyledCollapse
                          bordered={false}
                          accordion
                          expandIcon={({ isActive }) => (
                            <div>
                              {/* {expandIcon} */}
                              <Checkbox
                              // style={{ width: "0.7em", height: "0.7em" }}
                              />
                            </div>
                          )}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                          // onChange={handleCollapseChange}
                        >
                          {groupedData?.map((data, key) => {
                            const { inventories } = data;
                            return (
                              <CollapsePanel
                                key={`${data.key}`}
                                header={
                                  <CollapseHeader
                                    keyItem={`${data?.productId}`}
                                    image={data?.productImage}
                                    title={data?.productTitle}
                                    sku={data?.productSku}
                                    inventories={inventories}
                                    quantity={inventories?.length ?? 1}
                                  />
                                }
                              >
                                {inventories.map((inventory, recordKey) => {
                                  let rangeUsed = false;
                                  const { storeRequestPriceRange } = inventory;
                                  return (
                                    // <ProductRow key={inventory.id}>
                                    <>
                                      <div>
                                        {/* {columnSrc
                                          .find((col) => col.dataIndex === "id")
                                          ?.render(inventory.id, inventory)} */}
                                      </div>
                                      <ProductDetails>
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "8px",
                                            }}
                                          >
                                            <Checkbox
                                              style={{
                                                marginLeft: "auto",
                                                // width: "15px",
                                                backgroundColor: isSelected
                                                  ? "black"
                                                  : "transparent",
                                                alignSelf: "center",
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation(); // Prevent the parent click event
                                                OrderToggleSelection(inventory);
                                              }}
                                              checked={
                                                selectedProducts?.filter(
                                                  (i) =>
                                                    i?.product?.sku ===
                                                      inventory?.product?.sku &&
                                                    i?.option1Value ===
                                                      inventory?.option1Value
                                                )?.length > 0
                                              }
                                              // onChange={() =>
                                              //   handleCategoryChange(
                                              //     inventory.id,
                                              //     inventory
                                              //   )
                                              // }
                                            />
                                            <p
                                              style={{
                                                fontWeight: "600",
                                                fontSize: "0.9em",
                                                marginTop: "4px",
                                                lineHeight: "1rem",
                                                position: "relative",
                                                top: "6px",
                                              }}
                                            >
                                              {inventory?.option1Value}
                                            </p>
                                          </div>
                                          <QuantityInput>
                                            <Button
                                              onClick={() =>
                                                handleDecrement(inventory)
                                              }
                                            >
                                              -
                                            </Button>
                                            <InputNumber
                                              value={
                                                selectedProducts?.filter(
                                                  (i) =>
                                                    i?.product?.sku ===
                                                      inventory?.product?.sku &&
                                                    i?.option1Value ===
                                                      inventory?.option1Value
                                                )?.length
                                              }
                                              // onChange={(value: any) =>
                                              //   handleChange(
                                              //     value,
                                              //     record,
                                              //     "quantity"
                                              //   )
                                              // }
                                              defaultValue={0}
                                              min={0}
                                              controls={false}
                                            />
                                            <Button
                                              onClick={() =>
                                                handleIncrement(inventory)
                                              }
                                            >
                                              +
                                            </Button>
                                          </QuantityInput>
                                        </div>
                                        <div
                                          style={{ justifyContent: "right" }}
                                        >
                                          <div>
                                            <p
                                              style={{
                                                fontWeight: "600",
                                                fontSize: "0.9em",
                                                marginTop: "4px",
                                                lineHeight: "1rem",
                                              }}
                                            >
                                              {inventory.itemArray.length}{" "}
                                              Available
                                            </p>
                                          </div>
                                          <div>
                                            <p
                                              style={{
                                                fontWeight: "600",
                                                fontSize: "0.9em",
                                                marginTop: "4px",
                                                lineHeight: "1rem",
                                              }}
                                            >
                                              {getSymbolFromCurrency(
                                                store.currency
                                              )}
                                              {inventory.price
                                                ? Number(
                                                    inventory.price
                                                  ).toFixed(1)
                                                : "0"}
                                            </p>
                                          </div>
                                          <div>
                                            <p
                                              style={{
                                                fontWeight: "600",
                                                fontSize: "0.9em",
                                                marginTop: "4px",
                                                lineHeight: "1rem",
                                              }}
                                            >
                                              {getSymbolFromCurrency(
                                                store.currency
                                              )}
                                              {inventory.cost
                                                ? Number(
                                                    inventory.cost
                                                  ).toFixed(1)
                                                : "0"}
                                            </p>
                                          </div>
                                        </div>
                                      </ProductDetails>
                                    </>
                                    // </ProductRow>
                                  );
                                })}
                              </CollapsePanel>
                            );
                          })}
                        </StyledCollapse>
                      </>
                      // <>
                      //   {!inventoriesLoading &&
                      //     paginatedInventories?.rows.map((product, key) => {
                      //       let rangeUsed = false;
                      //       const { storeRequestPriceRange } = product;
                      //       if (
                      //         storeRequestPriceRange &&
                      //         storeRequestPriceRange[1] &&
                      //         storeRequestPriceRange[0]
                      //       ) {
                      //         rangeUsed = true;
                      //       }
                      //       return (
                      //         <ProductRow key={key}>
                      //           <ProductDetails>
                      //             <div>
                      //               <div
                      //                 style={{
                      //                   display: "flex",
                      //                   alignItems: "center",
                      //                 }}
                      //               >
                      //                 <input
                      //                   type="checkbox"
                      //                   style={{
                      //                     marginLeft: "auto",
                      //                     marginRight: "10px",
                      //                     borderRadius: "6px",
                      //                     padding: "5px",
                      //                     backgroundColor: isSelected
                      //                       ? "black"
                      //                       : "transparent",
                      //                   }}
                      //                   onClick={(e) => {
                      //                     e.stopPropagation(); // Prevent the parent click event
                      //                     toggleSelection(product);
                      //                   }}
                      //                   onChange={() =>
                      //                     handleCategoryChange(
                      //                       product.id,
                      //                       product
                      //                     )
                      //                   }
                      //                 />
                      //                 <img
                      //                   src={product?.product?.image}
                      //                   alt=""
                      //                   style={{
                      //                     height: "61px",
                      //                     width: "61px",
                      //                   }}
                      //                 />
                      //               </div>
                      //               <div style={{ flex: "0 0 250px" }}>
                      //                 <label
                      //                   style={{
                      //                     color: "grey",
                      //                     fontWeight: "500",
                      //                   }}
                      //                 >
                      //                   Name
                      //                 </label>
                      //                 <p
                      //                   style={{
                      //                     fontWeight: "bold",
                      //                     fontSize: "0.9em",
                      //                     marginTop: "4px",
                      //                     lineHeight: "1rem",
                      //                   }}
                      //                 >
                      //                   {product?.product?.title}
                      //                   {/* <a style={{ display: "block" }}>{inventory.code}</a> */}
                      //                 </p>
                      //               </div>

                      //               <div style={{ flex: "0 0 0px" }}>
                      //                 <label
                      //                   style={{
                      //                     color: "grey",
                      //                     fontWeight: "500",
                      //                   }}
                      //                 >
                      //                   SKU
                      //                 </label>
                      //                 <SkuDivMargin>
                      //                   <p
                      //                     style={{
                      //                       fontWeight: "bold",
                      //                       fontSize: "0.9em",
                      //                       marginTop: "4px",
                      //                       lineHeight: "1rem",
                      //                     }}
                      //                   >
                      //                     {product?.product?.sku?.slice(0, 12)}
                      //                     {product?.product?.sku?.length > 12
                      //                       ? "..."
                      //                       : null}
                      //                   </p>
                      //                 </SkuDivMargin>
                      //               </div>
                      //               <div style={{ flex: "0 0 0px" }}>
                      //                 <label
                      //                   style={{
                      //                     color: "grey",
                      //                     fontWeight: "500",
                      //                   }}
                      //                 >
                      //                   Condition
                      //                 </label>
                      //                 <p
                      //                   style={{
                      //                     fontWeight: "bold",
                      //                     fontSize: "0.9em",
                      //                     marginTop: "4px",
                      //                     lineHeight: "1rem",
                      //                   }}
                      //                 >
                      //                   {product?.option2Value}
                      //                 </p>
                      //               </div>
                      //               <div style={{ flex: "0 0 0px" }}>
                      //                 <label
                      //                   style={{
                      //                     color: "grey",
                      //                     fontWeight: "500",
                      //                   }}
                      //                 >
                      //                   Size
                      //                 </label>
                      //                 <p
                      //                   style={{
                      //                     fontWeight: "bold",
                      //                     fontSize: "0.9em",
                      //                     marginTop: "4px",
                      //                     lineHeight: "1rem",
                      //                   }}
                      //                 >
                      //                   {product?.option1Value}
                      //                 </p>
                      //               </div>
                      //               {/* <div style={{ flex: "0 0 0px" }}>
                      //             <label
                      //               style={{ color: "grey", fontWeight: "500" }}
                      //             >
                      //               Days Active
                      //             </label>
                      //             <p
                      //               style={{
                      //                 fontWeight: "bold",
                      //                 fontSize: "0.9em",
                      //                 marginTop: "4px",
                      //                 lineHeight: "1rem",
                      //               }}
                      //             >
                      //               {"size"}
                      //             </p>
                      //           </div> */}
                      //               <div style={{ flex: "0 0 0px" }}>
                      //                 <label
                      //                   style={{
                      //                     color: "grey",
                      //                     fontWeight: "500",
                      //                   }}
                      //                 >
                      //                   Price
                      //                 </label>
                      //                 <p
                      //                   style={{
                      //                     fontWeight: "bold",
                      //                     fontSize: "0.9em",
                      //                     marginTop: "4px",
                      //                     lineHeight: "1rem",
                      //                   }}
                      //                 >
                      //                   {getSymbolFromCurrency(store.currency)}
                      //                   {rangeUsed
                      //                     ? `${storeRequestPriceRange[0]} - ${storeRequestPriceRange[1]}`
                      //                     : product.price}
                      //                   {product?.requestedPrice && (
                      //                     <b
                      //                       style={{
                      //                         color: "green",
                      //                         display: "block",
                      //                       }}
                      //                     >
                      //                       {getSymbolFromCurrency(
                      //                         store.currency
                      //                       )}
                      //                       {product.requestedPrice}
                      //                     </b>
                      //                   )}
                      //                 </p>
                      //               </div>
                      //               <div style={{ flex: "0 0 0px" }}>
                      //                 <label
                      //                   style={{
                      //                     color: "grey",
                      //                     fontWeight: "500",
                      //                   }}
                      //                 >
                      //                   Cost
                      //                 </label>
                      //                 <p
                      //                   style={{
                      //                     fontWeight: "bold",
                      //                     fontSize: "0.9em",
                      //                     marginTop: "4px",
                      //                     lineHeight: "1rem",
                      //                   }}
                      //                 >
                      //                   {getSymbolFromCurrency(store.currency)}
                      //                   {product.cost
                      //                     ? Number(product.cost).toFixed(1)
                      //                     : "0"}
                      //                 </p>
                      //               </div>
                      //               {/* <div style={{ flex: "0 0 0px" }}>
                      //             <label
                      //               style={{ color: "grey", fontWeight: "500" }}
                      //             >
                      //               Projected Profit
                      //             </label>
                      //             <p
                      //               style={{
                      //                 fontWeight: "bold",
                      //                 fontSize: "0.9em",
                      //                 marginTop: "4px",
                      //                 lineHeight: "1rem",
                      //               }}
                      //             >
                      //               {getSymbolFromCurrency(store.currency)}
                      //               {product.cost
                      //                 ? Number(
                      //                     +product.price - +product.cost
                      //                   ).toFixed(1)
                      //                 : "0"}
                      //             </p>
                      //           </div> */}
                      //             </div>
                      //           </ProductDetails>
                      //         </ProductRow>
                      //       );
                      //     })}
                      // </>
                    )}
                  </>
                )}
              </>
            )}
          </Wrapper>
        </Container>
      </Drawer>
    </>
  );
}
