import { Button, Col, Row, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { CustomInvoiceModal, CustomModal } from "./inventoryStyle";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getInvoice } from "../../redux/actions/invoiceActions";
import { DownloadOutlined } from "@ant-design/icons";
import getSymbolFromCurrency from "currency-symbol-map";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

interface InvoiceModalProp {
  isInvoiceModal?: boolean;
  shippingCost?: number;
  invoiceTrackingCode?: string;
  closeInvoiceModal: () => void;
  sellerName?: string;
  invoiceRecord?: any;
  isSeller?: any;
}
export default function InvoiceModal({
  isInvoiceModal,
  shippingCost,
  invoiceTrackingCode,
  closeInvoiceModal,
  sellerName,
  invoiceRecord,
  isSeller,
}: InvoiceModalProp) {
  //   const [isInvoiceModal, setIsInvoiceModal] = useState(true);
  const { selectedProducts } = useAppSelector((state) => state.ProductReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [isEditing, setIsEditing] = useState(false);
  const [address, setAddress] = useState("Enter address here"); // Initial address
  const [invoiceData, setInvoiceData] = useState<any>();
  const dispatch = useAppDispatch();
  const { invoiceLoading } = useAppSelector((state) => state.InvoiceReducer);

  const fetchInvoice = async (invoiceId: any) => {
    try {
      const data = await dispatch(getInvoice(invoiceId, isSeller));
      setInvoiceData(data);
    } catch (error) {
      console.error("Failed to fetch invoice data:", error);
      setInvoiceData(null);
    }
  };

  useEffect(() => {
    const invoiceId = isSeller
      ? invoiceRecord?.sellerInvoiceId
      : invoiceRecord?.invoiceId;

    if (!invoiceId && !isInvoiceModal) return;

    fetchInvoice(invoiceId);
  }, [invoiceRecord, isSeller]);

  const sumByKey = (array, key) => {
    return array?.reduce((sum, item) => {
      // Convert the value to a number if it's a string
      const value = Number(item[key]) || 0;
      return sum + value;
    }, 0);
  };

  const totalQuantity = sumByKey(invoiceData?.inventories, "quantity");
  const totalPrice = sumByKey(invoiceData?.inventories, "price");
  const totalShipping = sumByKey(
    invoiceData?.inventories,
    isSeller ? "shippingCost" : "orderShippingCost"
  );
  const sumByKeyWithDeduction = (array, key, deductionKey) => {
    return array?.reduce((sum, item) => {
      // Convert the values to numbers if they're strings
      const value = Number(item[key]) || 0;
      const deduction = Number(item[deductionKey]) || 0;
      return sum + (value - deduction);
    }, 0);
  };
  const totalPriceWithShippingDeducted = sumByKeyWithDeduction(
    invoiceData?.inventories,
    "price",
    "shippingCost"
  );

  const handleSingleClick = () => {
    setIsEditing(true); // Enable editing on double click
  };

  const handleDownloadPdf = () => {
    const invoiceElement = document.getElementById("invoice-modal-content");

    if (invoiceElement) {
      html2canvas(invoiceElement, { scale: 2 })
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");

          // Calculate width and height to fit A4 size
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
          pdf.save("invoice.pdf");
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    }
  };

  const handleBlur = () => {
    setIsEditing(false); // Disable editing on blur (e.g., when focus is lost)
  };

  const handleChange = (e) => {
    setAddress(e.target.value); // Update the address when input changes
  };
  const handleCancel = () => {
    closeInvoiceModal();
  };
  function formatDateToLongDateString(isoDateStr) {
    const date = new Date(isoDateStr);
    const options: any = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  console.log("===##$$#===invoiceData@##$====", invoiceData?.inventories);

  return (
    <CustomInvoiceModal
      title={`Invoice ${invoiceData?.invoice?.trackingCode}`}
      visible={isInvoiceModal}
      onCancel={() => handleCancel()}
      width={800}
      footer={[
        // eslint-disable-next-line react/jsx-key
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            key="back"
            type="primary"
            style={{
              padding: "8px 16px",
              height: 52,
              borderRadius: "8px",
              border: "1px solid #DDDDDD",
              color: "black",
              background: "#F4F4F4",
            }}
            onClick={() => handleDownloadPdf()}
          >
            {<DownloadOutlined />} Download
          </Button>
          <Button
            key="submit"
            type="primary"
            style={{
              padding: "8px 20px",
              height: 52,
              borderRadius: "8px",
              background: "black",
              fontWeight: 500,
            }}
          >
            Accept Invoice
          </Button>
        </div>,
      ]}
    >
      {invoiceLoading ? (
        <Spin />
      ) : (
        <div id="invoice-modal-content">
          <div style={{ display: "grid", marginBottom: "1rem" }}>
            <span
              style={{
                color: "#989898",
                fontWeight: "500",
                marginBottom: "0.5rem",
              }}
            >
              Issued On:
            </span>
            <span>
              {formatDateToLongDateString(invoiceData?.invoice?.invoiceDate)}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem",
            }}
          >
            <div style={{ display: "grid" }}>
              <span
                style={{
                  color: "#989898",
                  fontWeight: "500",
                  marginBottom: "0.5rem",
                  cursor: "pointer", // Add cursor pointer to indicate clickable
                }}
                onClick={handleSingleClick} // Handle click to enable editing
              >
                Bill From
              </span>
              <span
                style={{ fontWeight: "500" }}
                onClick={handleSingleClick} // Handle click to enable editing
              >
                {invoiceData?.inventories[0]?.Seller}
              </span>
              {isEditing ? (
                <input
                  type="text"
                  value={address}
                  onChange={handleChange}
                  onBlur={handleBlur} // Handle blur to disable editing
                  autoFocus // Automatically focus on the input when editing starts
                  style={{
                    color: "#989898",
                    fontWeight: "500",
                    marginBottom: "0.5rem",
                    cursor: "pointer", // Add cursor pointer to indicate clickable
                  }}
                />
              ) : (
                <span
                  style={{
                    color: "#989898",
                    fontWeight: "500",
                    marginBottom: "0.5rem",
                    cursor: "pointer", // Add cursor pointer to indicate clickable
                  }}
                >
                  {address}
                </span>
              )}
            </div>
            <div style={{ display: "grid" }}>
              <span
                style={{
                  color: "#989898",
                  fontWeight: "500",
                  marginBottom: "0.5rem",
                }}
              >
                Bill To
              </span>
              <span style={{ fontWeight: "500" }}>{store?.name}</span>
            </div>
          </div>
          <span style={{ fontWeight: "700" }}>Invoice Item</span>
          <Row
            style={{
              background: "#F9FAFB",
              border: "1px solid #DDDDDD",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
              padding: "1rem",
            }}
          >
            <Col span={12} style={{ fontWeight: "500", color: "#2E2E2E" }}>
              Inventory
            </Col>
            <Col span={3} style={{ fontWeight: "500", color: "#2E2E2E" }}>
              Price
            </Col>
            <Col span={3} style={{ fontWeight: "500", color: "#2E2E2E" }}>
              Quantity
            </Col>
            <Col span={3} style={{ fontWeight: "500", color: "#2E2E2E" }}>
              Shipping
            </Col>
            <Col span={3} style={{ fontWeight: "500", color: "#2E2E2E" }}>
              Total Price
            </Col>
          </Row>
          {invoiceData?.inventories?.map((item, index) => (
            <Row
              key={index}
              style={{
                borderBottom: "1px solid #DDDDDD",
                borderLeft: "1px solid #DDDDDD",
                borderRight: "1px solid #DDDDDD",
                padding: "1rem",
              }}
            >
              <Col span={2}>
                <img src={item?.product?.image} height={57} width={57} />
              </Col>
              <Col span={10}>
                <p style={{ fontWeight: "500", color: "#2E2E2E" }}>
                  {item.title}
                </p>
                <p style={{ color: "#989898", fontWeight: "500" }}>
                  Size: {item.option1Value}
                </p>
              </Col>
              <Col span={3}>
                {getSymbolFromCurrency(store.currency)}
                {Number(item?.price) - Number(item?.shippingCost)}
              </Col>
              <Col span={3}>{item.quantity}</Col>
              <Col span={3}>
                {getSymbolFromCurrency(store.currency)}
                {isSeller ? item?.shippingCost : item?.orderShippingCost}
              </Col>
              <Col span={3}>
                {getSymbolFromCurrency(store.currency)}
                {item?.price}
              </Col>
            </Row>
          ))}
          <Row
            style={{
              borderBottom: "1px solid #DDDDDD",
              borderLeft: "1px solid #DDDDDD",
              borderRight: "1px solid #DDDDDD",
              padding: "1rem",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <Col
              span={15}
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#2E2E2E",
              }}
            >
              Total
            </Col>
            <Col
              span={3}
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#2E2E2E",
              }}
            >
              {totalQuantity}
            </Col>
            <Col
              span={3}
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#2E2E2E",
              }}
            >
              {getSymbolFromCurrency(store.currency)}
              {totalShipping}
            </Col>
            <Col
              span={3}
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#2E2E2E",
              }}
            >
              {getSymbolFromCurrency(store.currency)}
              {totalPrice}
            </Col>
          </Row>
        </div>
      )}
    </CustomInvoiceModal>
  );
}
